<template>
  <div class="" style="padding:2.5rem 1rem 2rem">
    <v-img src="../assets/successPage/logo 1 (1).png" class="img1"></v-img>
    <v-img
      src="../assets/successPage/undraw_winners_ao2o 1.png"
      class="img2"
    ></v-img>

    <p class="head">Invite Received!</p>
    <p class="content">We can't wait to meet you and your team</p>
  </div>
</template>

<script>
export default {
  name: 'success',
  methods: {
    trackPage() {
      this.$gtag('event', 'conversion', {
        send_to: 'AW-385314070/5M8XCLGnyYECEJba3bcB',
        value: 0.0,
        currency: 'NGN',
        transaction_id: '',
      });
    },
  },
  mounted() {
    this.trackPage();
  },
};
</script>

<style scoped lang="scss">
.img1 {
  margin-bottom: 2rem;
  width: 14rem;
  margin-left: auto;
  margin-right: auto;
}
.img2 {
  margin-bottom: 2rem;
  width: 28rem;
  margin-left: auto;
  margin-right: auto;
}
.content {
  margin-left: auto;
  margin-right: auto;
  font-size: 1rem;
  text-align: center;
}
.head {
  margin-left: auto;
  margin-right: auto;
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
}
</style>
